import { Inject, Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TenantService } from '../services/tenant.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { RequestHelpers } from '../helpers/request.helpers';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(
    private tenantService: TenantService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.tenantService.activeTenant$.pipe(
      take(1),
      switchMap((tenantId) => {
        const headerValue = tenantId;
        const newReq =
          headerValue &&
          (RequestHelpers.isIntranetRequest(req, this.appConfig) || RequestHelpers.isStaffRequest(req, this.appConfig))
            ? req.clone({
                setHeaders: {
                  'x-akelius-tenant': headerValue,
                },
              })
            : req;
        return next.handle(newReq);
      }),
      catchError((err) => throwError(err)),
    );
  }
}

export const TenantInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TenantInterceptor,
  multi: true,
};
