import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-10-22T13:08:07+0000',
  },
  git: {
    branchName: 'task/reset-selected-tenent-on-logout',
    commitHash: 'a48326e42067df53d56ab1cb9e36b36016aaa7ba',
  },
};
