import { ErrorHandler, inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ContextFacadeService } from '../facades/context-facade.service';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { NotFoundError } from '../common';
import { NotificationService } from '../services/notification.service';
import { LeafNode } from '../models/node-data.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ContextLoaderGuard  {
  private errorHandlerService = inject(ErrorHandler);

  constructor(
    private contextFacadeService: ContextFacadeService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // if origin is in the query parameters we use that as the context path
    // otherwise we strip the query param from the URL
    const contextUrl: string = next.queryParamMap.get('origin') ?? state.url.split('?')[0]; // remove the query params
    const isRouteNode: boolean = next.data?.isRouteNode !== false;

    // if the required context is already loaded, continue navigation, otherwise load the node
    return this.contextFacadeService.context$.pipe(
      take(1),
      map((context) => context?.path),
      switchMap((loadedPath) => {
        return loadedPath === contextUrl ? of(true) : this.loadNode(contextUrl, isRouteNode);
      })
    );
  }

  private loadNode(path: string, isRouteNode: boolean): Observable<boolean | UrlTree> {
    return this.contextFacadeService.loadNodes(path, isRouteNode).pipe(
      switchMap((_context: LeafNode) => {
        return of(true);
      }),
      catchError((error) => {
        console.error('error', error);
        // not found, show and error and redirect the user to root
        if (error instanceof NotFoundError) {
          this.notificationService.showErrorDialog('core.error.pathNotFound');
          return of(this.router.createUrlTree(['/']));
        } else {
          this.errorHandlerService.handleError(error);
          this.contextFacadeService.context$.pipe(take(1)).subscribe({
            next: (context) => {
              // if we already have context defined, we just show a popup
              // if this is the first node load then we consider the app to be unavailable
              if (context) {
                this.notificationService.showErrorDialog('core.error.navigationFailed');
              } else {
                this.router.navigateByUrl('/app-unavailable', { skipLocationChange: true }).then();
              }
            },
          });
        }

        return of(false);
      })
    );
  }
}
