import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromComponents from './components';
import { MatButton } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatButton],
  declarations: [...fromComponents.components],
  exports: [...fromComponents.components], // FIXME: try deleting this line
})
export class StatusPagesModule {}
