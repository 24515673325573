import { Inject, inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { combineLatestWith, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { APP_BASE_HREF } from '@angular/common';
import { STORAGE_OBJECT, URL_TENANT_ID, WINDOW_OBJECT } from '../core.module';
import { StorageObject } from '../models/storage.interfaces';
import { APP_CONFIG, AppConfig } from '../../../app.config';

export const STORAGE_KEY_TENANT_ID: string = 'selectedTenantId';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  APP_BASE_HREF = inject(APP_BASE_HREF);
  URL_TENANT_ID = inject(URL_TENANT_ID) as string;
  storage = inject(STORAGE_OBJECT) as StorageObject;
  window = inject(WINDOW_OBJECT) as Window;

  constructor(
    private router: Router,
    private auth: AuthService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(
      switchMap((loggedIn) => {
        if (!loggedIn) {
          this.auth.login({ redirectPath: state.url, organisationId: this.getSelectedTenantOrgId() });
          return of(false);
        }
        return this.auth.getActiveTenant$().pipe(
          combineLatestWith(this.auth.hasRole('User'), this.auth.getTenants$()),
          map(([tenantId, hasUserRole, allowedTenants]) => {
            // if the user does not have the User role then they are never allowed to use Intranet
            if (!hasUserRole || tenantId == undefined) {
              this.redirectToUnauthorizedPage();
              return false;
            }

            // if the tenant is not in the URL, then we add the first tenant from the user's token
            if (this.APP_BASE_HREF === '/') {
              this.window.location.href = `/t/${tenantId}` + state.url;
              return false;
            } else if (this.URL_TENANT_ID !== tenantId) {
              const urlTenant = allowedTenants.find((tenantOption) => tenantOption.id === this.URL_TENANT_ID);
              if (urlTenant !== undefined) {
                this.redirectToTenantSwitchPage();
                return false;
              } else {
                // if tenant in URL is not allowed or unknown redirect to unauthorized page
                this.redirectToUnauthorizedPage();
                return false;
              }
            }

            this.storage.localStore.setItem(STORAGE_KEY_TENANT_ID, tenantId);

            return true;
          }),
        );
      }),
    );
  }

  private redirectToUnauthorizedPage() {
    this.router.navigateByUrl('/app-access-unauthorised', { skipLocationChange: true });
  }

  private redirectToTenantSwitchPage() {
    this.router.navigate(['/app-tenant-switch'], {
      skipLocationChange: true,
      queryParams: { redirectUrl: `${this.window.location.pathname}${this.window.location.search}` },
    });
  }

  private getSelectedTenantOrgId(): string | null {
    const urlTenant = this.appConfig.tenants.find((availableTenant) => availableTenant.id === this.URL_TENANT_ID);

    if (urlTenant) {
      return urlTenant.orgId;
    }

    const storageTenant = this.appConfig.tenants.find(
      (availableTenant) => availableTenant.id === this.storage.localStore.getItem(STORAGE_KEY_TENANT_ID),
    );

    if (storageTenant) {
      return storageTenant.orgId;
    }

    return null;
    /*
    // defaults to residential
    const residentialOrgId = this.AVAILABLE_TENANTS.find(
      (availableTenant) => availableTenant.id === 'akelius-residential',
    )?.orgId;

    return residentialOrgId ? residentialOrgId : this.AVAILABLE_TENANTS[0].orgId;

     */
  }
}
