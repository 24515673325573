import { InjectionToken } from '@angular/core';

export interface AppConfig {
  backend: string;
  directUpload: string;
  envName: string;
  useSentry: boolean;
  dictionaryServiceEnv: string;
  propertyMediaService: string;
  auth0: {
    clientID: string;
    domain: string;
    audience: string;
    apiConnection?: string;
    organization?: string;
    scope: string;
    useOrganizations?: boolean;
  };
  isPropertyCatalogEditable: boolean;
  propertyCatalogApiRoot: string;
  staffApp: {
    backend: {
      rest: string;
      gql: string;
    };
    media: string;
  };
  tenants: {
    id: string;
    displayName: string;
    orgId: string;
  }[];
}

export const APP_CONFIG = new InjectionToken('app.config');
